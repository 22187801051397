<template>
    <v-container>
        
        <v-data-table
    :headers="headers"
    :options.sync="options"
    :items="reportData"
    :server-items-length="count"
    :items-per-page="10"
    :loading="loading"
    class="elevation-1"
  >
  <template v-slot:[`item.questionDate`]="{ item }">
    {{formatDate(item.questionDate)}}
  </template>
  <template v-slot:[`item.answerDate`]="{ item }">
    {{formatDate(item.answerDate)}}
  </template>
  </v-data-table>
    </v-container>
</template>


 "id": "16",
        "area": 1200,
        "location": "aaaaa",
        "environmentType": "bbb",
        "messageCount": "0",


<script>
export default {
    data(){
        return{
            options:{

            },
            loading: false,
              headers: [
                  {
                      text: 'Id',
                      value: 'id'
                  },
          {
            text: 'Area',
            value: 'area',
          },
          { text: 'Location', value: 'location' },
          { text: 'Environment Type', value: 'environmentType' },
          { text: 'Message Count', value: 'messageCount' },
          { text: 'Customer', value: 'customer.name' },
        ],
        reportData: [],
        count: null,
        }
    },

       watch: {
      options: {
        handler () {
          this.getMostMessages(this.options.page, this.options.itemsPerPage)
        },
        deep: true,
      },
    },

    methods:{
        getMostMessages(page,size){
            this.loading = true;
              this.$ReportApi.getMostMessages(page,size).then(data=>{
        
            this.reportData = data.data.data.list;
            this.count = data.data.data.count;
            this.loading = false;
        }).catch(error=>{
            console.log(error);
            this.loading = false;
        })
        }
    },

    mounted(){
        // this.getMostMessages();
    }
}
</script>