<template>
    <v-container>
        <v-card outlined class="pa-5">
            <v-tabs v-model="tab" background-color="transparent" grow>
                <v-tab>
                    Question Answer
                </v-tab>
                 <v-tab>
                    Conversation
                </v-tab>
                <v-tab>
                    Most Messages
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <QuestionAnswer/>
                </v-tab-item>
                <v-tab-item>
                     <Conversation/>
                    
                </v-tab-item>
                <v-tab-item>
                    <MostMessages/>
                </v-tab-item>
            </v-tabs-items>
        </v-card>

            
    </v-container>
        
    
</template>


<script>
import QuestionAnswer from "./QuestionAnswer.vue";
import MostMessages from "./MostMessages.vue";
import Conversation from "./Conversation.vue";

export default {
    components:{
        QuestionAnswer,
        MostMessages,
        Conversation
    },
    data(){
        return{
            tab: 0
        }
    },

}
</script>


<style scoped>

</style>